import * as React from "react"
// import { StaticImage } from "gatsby-plugin-image"
// import AniLink from "gatsby-plugin-transition-link/AniLink";
// import Link from '../components/TransitionLinks/Link';
// import { TransitionPortal } from "gatsby-plugin-transition-link";
import { Link } from "gatsby";

import logo from '../images/logo.svg';

const links = [
  {
    text: "Meet Kim",
    url: "/about-kim",
  },
  {
    text: "How We Win",
    url: "/how-we-win",
  },
  {
    text: "Endorsements",
    url: "/endorsements",
  },
  {
    text: "Press",
    url: "/press",
    // subMenu: [
    //   {
    //     text: "Truyền Thông",
    //     url: "/press-vi"
    //   }
    // ],
  },
  // {
  //   text: "Platform",
  //   url: "javscript:void(0)",
  //   subMenu: [
  //     {
  //       text: `Why I'm Running`,
  //       url: `/platform`,
  //     },
  //     {
  //       text: `Homelessness Action Plan`,
  //       url: `/homelessness-action-plan`,
  //     },
  //   ],
  // },
  // {
  //   text: "Endorsements",
  //   url: "/endorsements",
  // },
  // {
  //   text: "News",
  //   url: "/in-the-news",
  // },
  {
    text: "Gallery",
    url: "/gallery",
  },
  {
    text: "Volunteer",
    url: "/volunteer",
  },
]

export default class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      scroll: false,
      scrolly: 0,
    };
    this.handleScroll = this.handleScroll.bind(this);
  }

  toggleMenu = () => {
    const currentState = this.state.active;
    this.setState({ active: !currentState });
  }

  componentDidMount() {
    this.handleScroll();
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    this.handleScroll();
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll(event) {
    this.setState({scrolly: window.pageYOffset});
    if (this.state.scrolly > 100) {
      this.setState({scroll: true});
    } else {
      this.setState({scroll: false});
    }
  }

  render() {
    return (
        <React.Fragment>
          <header
          className={`
          animate__animated
          fixed
          top-0
          inset-x-0
          z-50
          px-1
          py-1
          md:px-5
          md:py-5
          flex
          justify-between
          items-center
          transition-all
          duration-100
          ease-in-out
          bg-gradient-to-b
          ${this.state.scroll ? 'from-primary to-primary-dark drop-shadow-md' : 'from-black/75'}`}>
            {/* <AniLink fade to="/"> */}
            <Link to="/">
              <img src={logo} alt="Kim Nguyen-Penaloza For Supervisor" className="absolute top-0 left-1 md:left-5 z-20 block w-[125px] md:w-[200px] h-auto" />
            </Link>
            {/* </AniLink> */}
            <nav className="relative flex flex-row-reverse lg:flex-row items-center">
              <button className="relative group lg:hidden" onClick={this.toggleMenu}>
                <div className={`relative flex overflow-hidden items-center justify-center rounded-full w-[40px] h-[40px] transform transition-all bg-secondary ring-0 ring-gray-300 hover:ring-8 ring-opacity-30 duration-200 shadow-md  ${this.state.active ? 'ring-4' : ''}`}>
                  <div className={`flex flex-col space-y-1 justify-center w-[20px] h-[20px] transform transition-all duration-300 origin-center overflow-hidden`}>
                    <div className={`bg-black h-[2px] w-4 mx-auto transform transition-all duration-300 origin-left delay-100 ${this.state.active ? 'translate-y-6' : ''}`}></div>
                    <div className={`bg-black h-[2px] w-4 mx-auto rounded transform transition-all duration-300 delay-75 ${this.state.active ? 'translate-y-6' : ''}`}></div>
                    <div className={`bg-black h-[2px] w-4 mx-auto transform transition-all duration-300 origin-left ${this.state.active ? 'translate-y-6' : ''}`}></div>
  
                    <div className={`absolute items-center justify-between transform transition-all duration-500 top-1.5 -translate-x-10 flex w-0  ${this.state.active ? 'translate-x-0 w-12' : ''}`}>
                      <div className={`absolute bg-black h-[2px] w-5 transform transition-all duration-500 rotate-0 delay-300  ${this.state.active ? 'rotate-45' : ''}`}></div>
                      <div className={`absolute bg-black h-[2px] w-5 transform transition-all duration-500 -rotate-0 delay-300 ${this.state.active ? '-rotate-45' : ''}`}></div>
                    </div>
                  </div>
                </div>
              </button>
              <ul className={`absolute w-52 rounded-md overflow-hidden lg:overflow-visible top-16 right-0 bg-primary lg:block lg:static lg:bg-transparent lg:rounded-none lg:w-auto ${this.state.active ? 'block' : 'hidden'}`}>
                {links.map(link => (
                  <li className="block relative lg:inline-block md:mr-3 xl:mr-7 border-b border-primary-light lg:border-none group" key={link.text}>
                    <Link
                    activeClassName="border-b-2 border-secondary"
                    className="
                    block
                    font-light
                    text-white
                    text-base
                    lg:text-sm
                    2xl:text-base
                    transition
                    duration-300
                    ease-in-out
                    hover:bg-primary-light
                    lg:hover:bg-transparent
                    lg:hover:underline
                    decoration-transparent
                    lg:hover:decoration-secondary
                    decoration-2
                    py-1.5
                    px-4
                    lg:py-0
                    lg:px-0
                    group
                    " 
                    to={`${link.url}`}>
                      <span className="drop-shadow-lg">{link.text}</span>
                      {link.subMenu && link.subMenu.length > 0 ? (
                        <svg xmlns="http://www.w3.org/2000/svg" width="23.616" height="13.503" viewBox="0 0 23.616 13.503" role="img" aria-hidden="true" className="inline-block fill-white w-2 ml-2 group-hover:rotate-180 transition">
                          <title>Down Arrow</title>
                          <path id="Icon_ionic-ios-arrow-down" data-name="Icon ionic-ios-arrow-down" d="M18,20.679l8.93-8.937a1.681,1.681,0,0,1,2.384,0,1.7,1.7,0,0,1,0,2.391L19.2,24.258a1.685,1.685,0,0,1-2.327.049L6.68,14.14a1.688,1.688,0,0,1,2.384-2.391Z" transform="translate(-6.188 -11.246)"/>
                        </svg>
                      ) : null}
                    </Link>
                    {link.subMenu && link.subMenu.length > 0 ? (
                      <ul aria-label="submenu" className="hidden lg:block lg:invisible bg-primary-dark lg:absolute lg:w-44 lg:rounded-md overflow-hidden top-6 left-0 lg:bg-primary group-hover:block lg:group-hover:visible transition-all duration-200 ease-linear">
                        {link.subMenu.map((subLink) => (
                          <li key={subLink.text} className="block border-b border-primary lg:border-primary-light">
                            <Link
                            to={subLink.url}
                            className="
                            block
                            font-light
                            text-white
                            transition
                            duration-300
                            ease-in-out
                            hover:bg-primary
                            lg:hover:bg-primary-light
                            decoration-transparent
                            decoration-2
                            py-1.5
                            px-4
                            ">{subLink.text}</Link>
                          </li>
                        ))}
                      </ul>
                    ) : null}
                  </li>
                ))}
                <li className="hidden mt-3 lg:mt-auto lg:inline-block px-4 pb-4 lg:px-0 lg:pb-0">
                  <a href="https://secure.actblue.com/donate/votekimnguyen"
                  className="
                  font-bold
                  text-black
                  bg-secondary
                  text-lg
                  lg:text-base
                  2xl:text-lg
                  rounded-full
                  py-1
                  px-6
                  text-center
                  transition-all
                  duration-500
                  hover:bg-secondary-dark
                  ">
                    Donate Now
                    <svg xmlns="http://www.w3.org/2000/svg" width="13.503" height="23.616" viewBox="0 0 13.503 23.616" role="img" aria-hidden="true" className="inline-block fill-[#3D3D3D] w-1.5 ml-2 align-middle -mt-1">
                      <path id="Icon_ionic-ios-arrow-forward" data-name="Icon ionic-ios-arrow-forward" d="M20.679,18,11.742,9.07a1.681,1.681,0,0,1,0-2.384,1.7,1.7,0,0,1,2.391,0L24.258,16.8a1.685,1.685,0,0,1,.049,2.327L14.14,29.32a1.688,1.688,0,0,1-2.391-2.384Z" transform="translate(-11.246 -6.196)"/>
                    </svg>
                  </a>
                </li>
              </ul>
              <LangSelect pathname={this.props.pathname} />
            </nav>
          </header>
          <a href="https://secure.actblue.com/donate/votekimnguyen"
            className="
            lg:hidden
            font-bold
            text-black
            bg-secondary
            text-sm
            rounded-full
            py-1
            px-4
            text-center
            transition-all
            duration-500
            mr-2
            hover:bg-secondary-dark
            fixed
            top-[90%]
            right-0
            z-40
            ">
              Donate Now
              <svg xmlns="http://www.w3.org/2000/svg" width="13.503" height="23.616" viewBox="0 0 13.503 23.616" role="img" aria-hidden="true" className="inline-block fill-[#3D3D3D] w-1.5 ml-2 align-middle -mt-1">
                <path id="Icon_ionic-ios-arrow-forward" data-name="Icon ionic-ios-arrow-forward" d="M20.679,18,11.742,9.07a1.681,1.681,0,0,1,0-2.384,1.7,1.7,0,0,1,2.391,0L24.258,16.8a1.685,1.685,0,0,1,.049,2.327L14.14,29.32a1.688,1.688,0,0,1-2.391-2.384Z" transform="translate(-11.246 -6.196)"/>
              </svg>
            </a>
        </React.Fragment>
    );
  }
}

export class LangSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newpath: '',
    };
    this.changeLang = this.changeLang.bind(this);
  }

  componentDidMount() {
    this.changeLang();
  }

  componentWillUnmount() {
    this.changeLang();
  }

  changeLang() {
    var currentPath = this.props.pathname;
    var newPath = '/vi' + currentPath

    if (currentPath == '/404') {
      newPath = '/vi'
    }

    // console.log(currentPath);
    // console.log(newPath);

    this.setState({newpath: newPath});
  }

  render() {
    return (
      <Link
        to={this.state.newpath}
        className="
        mr-2
        lg:mr-0
        lg:ml-2
        text-white
        text-xs
        2xl:text-sm
        rounded-full
        py-1
        px-4
        text-center
        bg-transparent
        border-2 
        border-white 
        border-solid 
        hover:border-secondary 
        transition 
        duration-300
        ">
          Bằng tiếng Việt
      </Link>
    );
  }
}