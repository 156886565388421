import * as React from "react"
import { SEO } from "../components/seo"
import Layout from '../components/layout';
import { StaticImage } from "gatsby-plugin-image"
import ScrollAnimation from 'react-animate-on-scroll';
// import { Link } from "gatsby";

const AboutPage = (props) => {
  return (
    <Layout pathname={props.location.pathname}>
      <section className="
      h-[600px]
      lg:h-[750px]
      bg-primary
      bg-[url('../images/about-kim-hero-mobile.jpg')]
      md:bg-[url('../images/about-kim-hero.jpg')]
      bg-no-repeat
      bg-center
      bg-cover
      relative">
        <div className="absolute w-full lg:h-3/5 inset-X-0 bottom-0 pt-28 pb-20 lg:pt-10 lg:pb-20 z-10 bg-gradient-to-t from-primary px-4 lg:px-20 lg:flex lg:flex-col lg:justify-end text-center">
        <h1 className="animate__animated animate__fadeInDown animate__slower font-heading text-4xl md:text-6xl text-white mb-2">Meet <span className="font-bold text-secondary">Kim Nguyen-Penaloza</span></h1>
          <p className="animate__animated animate__fadeInUp animate__slower text-xl font-light text-white drop-shadow-sm leading-relaxed">The daughter of a Vietnamese refugee and a Mexican immigrant, Kim Nguyen’s story is the quintessential California tale of sacrifice, hard work, and dedication to community.</p>
        </div>
      </section>
      <section className="w-full mt-4 lg:mt-14 mx-auto px-4 lg:px-36 text-gray-700 flex flex-col lg:flex-row items-start justify-center">
        <div className="w-full lg:w-1/4 columns-2 order-last lg:order-first">
          <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>
            <StaticImage src="../images/about-kim/about-kim-01.jpg" alt="" placeholder="blurred" layout="fullWidth" className="rounded-md shadow-lg mb-5" />
          </ScrollAnimation>
          <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>
            <StaticImage src="../images/about-kim/about-kim-02.jpg" alt="" placeholder="blurred" layout="fullWidth" className="rounded-md shadow-lg mb-5" />
          </ScrollAnimation>
          <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>
            <StaticImage src="../images/about-kim/about-kim-03.jpg" alt="" placeholder="blurred" layout="fullWidth" className="rounded-md shadow-lg mb-5" />
          </ScrollAnimation>
          <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>
            <StaticImage src="../images/kim-and-dog.jpg" alt="" placeholder="blurred" layout="fullWidth" className="rounded-md shadow-lg mb-5" />
          </ScrollAnimation>
          <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>
            <StaticImage src="../images/about-kim/about-kim-04.jpg" alt="" placeholder="blurred" layout="fullWidth" className="rounded-md shadow-lg mb-5" />
          </ScrollAnimation>
          <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>
            <StaticImage src="../images/about-kim/about-kim-06.jpg" alt="" placeholder="blurred" layout="fullWidth" className="rounded-md shadow-lg mb-5" />
          </ScrollAnimation>
        </div>
        <div className="text-base leading-loose space-y-6 pb-10 w-full lg:w-1/2 lg:px-12">
          <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><p><span className="font-bold text-lg text-primary">Raised by her Vietnamese father and Mexican mother</span>, Kim Nguyen-Penaloza learned early on to fight for her accomplishments. She was the first in her family to graduate high school and college. At just 25, she won a seat on the Garden Grove City Council, becoming the youngest person ever elected. In 2020, Kim was overwhelmingly re-elected with more than 75% of the vote.</p></ScrollAnimation>
  
          <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><p>As a Councilmember, Kim has been a fierce advocate for neighborhoods—championing new economic development, infrastructure projects, and park rehabilitation while reducing crime and homelessness. Kim is a leader on women’s issues, standing up to harassment and seeking equality and justice throughout Southern California. She has fought to ensure that immigrants, like her mother, have the dignity and opportunity to thrive in our communities.</p></ScrollAnimation>
  
          <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><p>In Congress, Kim will work to restore integrity and accountability to a broken Washington. She has a proven record of bipartisanship, working with her colleagues on council to deliver meaningful policies to strengthen small businesses and create economic opportunity. Kim understands the challenges we face in Southern California, especially the rising cost of living, housing, education, and healthcare. In Congress, she will work to reduce medical and prescription drug costs, increase housing affordability, invest in job training to help raise wages, and reduce inflation so working families in Southern California can thrive.</p></ScrollAnimation>

          <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}><p>A lifelong resident of CA-45, Kim attended Clinton-Mendenhall Elementary, Doig Intermediate, and Santiago High School. She will bring a much-needed public health background to congress, having formerly worked for CalOptima and now as a Program Manager for a large health plan.</p></ScrollAnimation>

        </div>
        <div className="w-full lg:w-1/4 columns-2">
          <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>
            <StaticImage src="../images/about-kim/about-kim-07.jpg" alt="" placeholder="blurred" layout="fullWidth" className="rounded-md shadow-lg mb-5" />
          </ScrollAnimation>
          <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>
            <StaticImage src="../images/about-kim/about-kim-08.jpg" alt="" placeholder="blurred" layout="fullWidth" className="rounded-md shadow-lg mb-5" />
          </ScrollAnimation>
          <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>
            <StaticImage src="../images/about-kim/about-kim-09.jpg" alt="" placeholder="blurred" layout="fullWidth" className="rounded-md shadow-lg mb-5" />
          </ScrollAnimation>
          <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>
            <StaticImage src="../images/about-kim/about-kim-10.jpg" alt="" placeholder="blurred" layout="fullWidth" className="rounded-md shadow-lg mb-5" />
          </ScrollAnimation>
          <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>
            <StaticImage src="../images/about-kim/about-kim-11.jpg" alt="" placeholder="blurred" layout="fullWidth" className="rounded-md shadow-lg mb-5" />
          </ScrollAnimation>
          <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>
            <StaticImage src="../images/about-kim/about-kim-12.jpg" alt="" placeholder="blurred" layout="fullWidth" className="rounded-md shadow-lg mb-5" />
          </ScrollAnimation>
          <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>
            <StaticImage src="../images/about-kim/about-kim-13.jpg" alt="" placeholder="blurred" layout="fullWidth" className="rounded-md shadow-lg mb-5" />
          </ScrollAnimation>
        </div>
      </section>
    </Layout>
  )
}

export default AboutPage

export const Head = ({location}) => <SEO title="About Kim" pathname={location.pathname}  />
